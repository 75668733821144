import { URL_SERVER, APP_NAME, APP_TOKEN } from "../utilities/config";

export async function translateText(text, lang) {
  try {
    return await fetch(URL_SERVER + "/translateText", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: text,
        lang: lang,
        applicationRequesting: APP_NAME,
        applicationToken: APP_TOKEN,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        throw "Server non raggiungibile";
      })
      .then((res) => {
        return res.textTranslated;
      });
  } catch (error) {
    throw "Server non raggiungibile";
  }
}

export async function translateToEnglish(text) {
  return translateText(text, "EN");
}

export async function translateToItalian(text) {
  return translateText(text, "IT");
}
