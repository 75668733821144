import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Chat } from "../Chat/Chat.js";
import { Menu } from "../Menu/Menu.js";
import "./ChatContainer.css";
import { botsInfo } from "../../bots.js";
import { generateAnswer } from "../../BackEnd/BackEndConnector";

export function ChatContainer({ language = "EN", setLanguage, chosenBot }) {
  const botInfo = botsInfo[chosenBot];
  const initialChatState = botInfo.firstMessage
    ? [{ text: botInfo.firstMessage, sender: "left" }]
    : [];

  const [chatLog, setChatLog] = useState(initialChatState);
  const [awaitingAnswer, setAwaitingAnswer] = useState(false);

  function clearChat() {
    console.log(initialChatState);
    setChatLog(initialChatState);
  }

  function changeLanguage(lang) {
    clearChat();
    setLanguage(lang);
  }

  function addMessageToChat(inputEN, inputIT, sender) {
    setChatLog(
      chatLog.concat({
        text: { EN: inputEN, IT: inputIT },
        sender: sender,
      })
    );
  }

  async function sendMessage(event) {
    if (event.key !== "Enter") return;
    event.preventDefault();
    const textArea = event.target;
    const userInput = textArea.value.trim();
    textArea.value = "";

    addMessageToChat(
      language === "EN" ? userInput : undefined,
      language === "IT" ? userInput : undefined,
      "right"
    );
  }

  useEffect(() => {
    if (
      chatLog.length > 0 &&
      _.last(chatLog).sender === "right" &&
      !awaitingAnswer
    ) {
      setAwaitingAnswer(true);
      generateAnswer(chatLog, botInfo, language).then((result) => {
        addMessageToChat(result.EN, result.IT, "left");
        setAwaitingAnswer(false);
      });
    }
  }, [chatLog, awaitingAnswer]);

  return (
    <section
      className="chat-overlay"
      onLoad={() => document.getElementById("input-user-chat").focus()}
    >
      <Menu bot={botInfo} language={language} setLanguage={changeLanguage} />
      <Chat
        chatLog={chatLog}
        awaitingAnswer={awaitingAnswer}
        language={language}
        botClass={botInfo.CSSClass}
      />
      <div className="input-text-container">
        <textarea
          id="input-user-chat"
          placeholder={language === "EN" ? "Write here" : "Scrivimi qui..."}
          rows="3"
          onKeyUp={(e) => sendMessage(e)}
        />
      </div>
    </section>
  );
}
