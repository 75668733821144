import React from "react";
import "./Message.css";

function checkLinks(text) {
  const reg =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

  let parts = text.split(reg);
  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = (
      <a
        key={"link" + i}
        href={parts[i].substr(0, 4) === "http" ? parts[i] : "//" + parts[i]}
        target="_blank"
        rel="noreferrer"
      >
        {parts[i]}
      </a>
    );
  }
  return parts;
}

export function Message({ text, sender, waiting, botClass }) {
  return (
    <div
      className={
        sender === "left"
          ? "text-message sender-left"
          : "text-message sender-right"
      }
    >
      <div
        className={
          (sender === "left"
            ? "text-message-content sender-left-content "
            : "text-message-content sender-right-content ") + botClass
        }
      >
        {waiting ? (
          <>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </>
        ) : (
          checkLinks(text)
        )}
      </div>
    </div>
  );
}
