import { URL_SERVER, APP_NAME, APP_TOKEN } from "../utilities/config";
import { translateToEnglish, translateToItalian } from "./Translation";

const MAX_CHAT_LENGTH = 10;

function formatChat(chat, characterName) {
  return chat
    .slice(chat.length - MAX_CHAT_LENGTH, chat.length)
    .map((d) =>
      d.sender === "left" ? characterName + ": " + d.text : "Q: " + d.text
    )
    .join("\n");
}

function generatePrompt(chat, bot) {
  const botName = "Jacopo";
  const intro = bot.intro;
  const formattedChat = formatChat(chat, botName);
  return intro + "\n\n" + formattedChat + "\n" + botName + ":";
}

async function translateChatLog(chatLog) {
  for (const message of chatLog) {
    message.text.EN =
      message.text.EN !== undefined
        ? message.text.EN
        : await translateToEnglish(message.text.IT);
  }
}

export async function generateAnswer(chatLog, bot, language) {
  if (language !== "EN") {
    await translateChatLog(chatLog);
  }

  const prompt = generatePrompt(
    chatLog.map((message) => ({
      text: message.text.EN,
      sender: message.sender,
    })),
    bot
  );
  const response = await sendRequestOpenAI(prompt, 100, bot.model, bot.params);
  const responseTranslated =
    language === "EN" ? null : await translateToItalian(response);
  return { EN: response, IT: responseTranslated };
}

export async function sendRequestOpenAI(
  prompt,
  maxTokens,
  gpt3Model,
  gpt3params
) {
  try {
    return await fetch(URL_SERVER + "/gpt3connector", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        applicationRequesting: APP_NAME,
        applicationToken: APP_TOKEN,
        prompt: prompt,
        maxTokens: maxTokens,
        gpt3Model: gpt3Model,
        params: gpt3params,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw "Errore di connessione con il server";
        }
      })
      .then((res) => {
        return res.generatedText;
      });
  } catch (error) {
    throw "Errore di connessione con il server";
  }
}
