import React, { useRef, useEffect } from "react";
import { Message } from "../Message/Message";
import "./Chat.css";

export function Chat({ chatLog, awaitingAnswer, language, botClass }) {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatLog, awaitingAnswer]);

  return (
    <div className="chat-container">
      {chatLog.map((d, i) => (
        <Message
          text={d.text[language]}
          sender={d.sender}
          key={"message-" + String(i)}
          botClass={botClass}
        />
      ))}
      {awaitingAnswer ? <Message waiting={true} sender={"left"} /> : ""}
      <div id="fake-last-element" ref={messagesEndRef} />
    </div>
  );
}
