import "./Menu.css";

export function Menu({ bot, language, setLanguage }) {
  return (
    <>
      <div className="menualto">
        <div
          className="menu-option"
          style={{
            display:
              language === "EN" || bot.name === "JacoBot [Beta]" ? "none" : "",
          }}
        >
          <a href="https://chatbob.net/info" target="_blank" rel="noreferrer">
            {language === "EN" ? "More" : "Scopri di più"}
          </a>
        </div>
        <div
          className="menu-option"
          style={{ display: bot.name === "JacoBot [Beta]" ? "none" : "" }}
        >
          <a href="mailto:hello@chatbob.net" target="_blank" rel="noreferrer">
            {language === "EN" ? "Contacts" : "Contatti"}
          </a>
        </div>
        <div
          className="menu-option"
          style={{ display: bot.name === "JacoBot [Beta]" ? "" : "none" }}
        >
          <a
            href="https://www.jacopoperfetti.com/"
            target="_blank"
            rel="noreferrer"
          >
            About
          </a>
        </div>
        <div
          className="menu-option"
          onMouseDown={() => setLanguage(language === "EN" ? "IT" : "EN")}
        >
          {language === "EN" ? "Italiano" : "English"}
        </div>
      </div>
      <div className="top-chat-bar">
        <img
          alt="Profile"
          className="small-profile-img"
          src={bot !== null ? bot.srcSmall : ""}
        />
        <div className="profile-name">{bot !== null ? bot.name : ""}</div>
      </div>
    </>
  );
}
