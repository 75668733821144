import { ChatContainer } from "./Components/ChatContainer/ChatContainer";
import React, { useState } from "react";
import { DEFAULT_LANG } from "./utilities/config";

function getDefaultLang() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const defaultLang = urlParams.get("lang");
  return defaultLang !== null ? defaultLang : DEFAULT_LANG;
}

function App() {
  const [language, setLanguage] = useState(getDefaultLang());

  return (
    <div className="App">
      <ChatContainer
        language={language}
        setLanguage={setLanguage}
        chosenBot={process.env.REACT_APP_CHATBOT_NAME}
      />
    </div>
  );
}

export default App;
